import {
  Row,
  Col,
  Modal,
  Button,
  Input,
  Form,
  notification,
  Radio,
} from "antd";
import { useState } from "react";
import axios from 'axios';
import "react-quill/dist/quill.snow.css";
import config from "../../utils/config.js";

export default function Create({ open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios.post(`${config.server_ip}comunicacao/addComunicacaoMiniOral`, {
      data: values
    }).then((resData) => {
      close();
      setIsButtonLoading(false);
      form.resetFields();
      notification.success({
        message: 'Yeah!',
        description: 'Comunicação mini oral foi adicionada com sucesso!'
      })
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-comunicacao-mini-oral"
      title={`Adicionar Comunicação Mini Oral`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button className="mr-20" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={handleCloseModal}>
          Cancelar
        </Button>
      ]}
    >
      <Form
        form={form}
        id="add-comunicacao-mini-oral-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Nome"
              rules={[{ required: true, message: 'Este é um campo obrigatório' }]}
            >
              <Input size="large" placeholder="Nome..." />
            </Form.Item>
            <Form.Item
              name="link"
              label="Link"
            >
              <Input size="large" placeholder="Link..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
