import {
  Row,
  Col,
  Button,
  Spin,
  Divider,
  Pagination,
  Empty,
  Form,
  Input,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { IoPersonCircleOutline } from "react-icons/io5";

import config from "../../utils/config";
import Create from "../../components/Faculty/Create";
import Update from "../../components/Faculty/Update";
import Delete from "../../components/Faculty/Delete";

import "./Faculty.css";
import Search from "antd/es/input/Search";
import endpoints from "../../utils/endpoints";

function Faculty() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.faculty.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let auxArray = [];
    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];

      let aux = {
        key: i,
        img: (
          <div
            className="img-preview"
            style={{
              backgroundImage: arrayData.img
                ? `url('${config.server_ip}media/${arrayData.img}')`
                : `url('${config.server_ip}media/User-default.svg')`,
            }}
          ></div>
        ),
        name: arrayData.name,
        specialty: arrayData.specialty,
        institution: arrayData.institution,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined
              className="edit-action"
              onClick={() => handleOpenUpdate(arrayData)}
            />
            <DeleteOutlined
              className="delete-action"
              onClick={() => handleOpenDelete(arrayData)}
            />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetData();
  }

  function handleSearch(_, allFields) {
    let value = allFields.search;
    setSearchText(value);
    const newData = data.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    handlePrepareData(newData);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update
        data={selectedData}
        open={isOpenUpdate}
        close={handleCloseUpdate}
      />
      <Delete
        data={selectedData}
        open={isOpenDelete}
        close={handleCloseDelete}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={4} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Faculty</h3>
        </Col>
        <Col span={20} className="d-flex jc-end ai-center">
          <Form layout="inline" form={form} onValuesChange={handleSearch}>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                className="mr-20"
                size="large"
                placeholder="Procurar..."
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={2}>
                          <div className="d-flex jc-center ai-center mr-20">
                            {item.img}
                          </div>
                        </Col>
                        <Col span={10}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Especialidade</span>
                            <span className="f-18 semi-bold">
                              {item.specialty ? item.specialty : "TBA"}
                            </span>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Instituição</span>
                            <span className="f-18 semi-bold">
                              {item.institution ? item.institution : "TBA"}
                            </span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={data.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="Esta tabela está vazia" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Faculty;
