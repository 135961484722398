import {
  Row,
  Col,
  Modal,
  Button,
  Input,
  Form,
  notification,
  DatePicker,
} from "antd";
import { useState } from "react";
import axios from 'axios';
import "react-quill/dist/quill.snow.css";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

export default function Create({ open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios.post(endpoints.notification.create, {
      data: values
    }).then((resData) => {
      close();
      setIsButtonLoading(false);
      form.resetFields();
      notification.success({
        message: 'Yeah!',
        description: 'Notificação foi adicionada com sucesso!'
      })
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-notification"
      title={`Adicionar notificação`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
      ]}
    >
      <Form
        form={form}
        id="add-notification-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Form.Item
          name="title"
          label="Título"
          rules={[{ required: true, message: 'Este é um campo obrigatório' }]}
        >
          <Input size="large" placeholder="Título..." />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[{ required: true, message: 'Este é um campo obrigatório' }]}
        >
          <Input size="large" placeholder="Descrição..." />
        </Form.Item>
        <Form.Item
          name="date_time"
          label="Data"
        >
          <DatePicker className="w-100" showTime />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.date_time !== currentValues.date_time}
        >
          {({ getFieldValue }) => (
            <div className="d-flex jc-end ai-center">
              <Button
                className="mr-10"
                size="large"
                type="primary"
                onClick={form.submit}
                loading={isButtonLoading}
                disabled={!getFieldValue('date_time')}
              >
                Agendar
              </Button>
              <Button
                className="mr-10"
                size="large"
                type="primary"
                onClick={form.submit}
                loading={isButtonLoading}
                disabled={getFieldValue('date_time')}
              >
                Enviar
              </Button>
              <Button
                disabled={isButtonLoading}
                size="large"
                onClick={handleCloseModal}
              >
                Cancelar
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
