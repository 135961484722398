import {
  Row,
  Col,
  Divider,
  Tabs,
  Form,
  notification,
  Switch,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';

import config from "../../utils/config.js";
import SettingsVersion from "./SettingsVersion";
import SettingsDays from "./SettingsDays";

import "react-quill/dist/quill.snow.css";
import "./Settings.css";
import endpoints from "../../utils/endpoints.js";
import SettingsRooms from "./SettingsRooms.js";
import SettingsSponsor from "./SettingsSponsor.js";

function Settings() {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [settingsEvent, setSettingsEvent] = useState([]);
  const [settingsApp, setSettingsApp] = useState([]);
  const [form] = Form.useForm();
  const [formHalfAccess] = Form.useForm();

  useEffect(() => {
    handleGetDefinitions();
  }, []);

  function handleGetDefinitions() {
    setIsLoading(true);
    axios.get(endpoints.eventSettings.read).then((responseEvent) => {
      setSettingsEvent(responseEvent.data)
      axios.get(endpoints.appSettings.read).then((response) => {

        let settingsDataPrivateAccess = response.data.filter((item) => item.name === 'close_app')[0];
        let settingsDataHalfAccess = response.data.filter((item) => item.name === 'access_profile')[0];

        console.log(settingsDataHalfAccess)
        form.setFieldsValue(JSON.parse(settingsDataPrivateAccess.json));
        formHalfAccess.setFieldsValue(JSON.parse(settingsDataHalfAccess.json));

        setSettingsApp(response.data);
        setIsLoading(false);
      })
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }

  function handleChangeHalfAccess(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    axios.post(endpoints.appSettings.update, {
      data: { values: values, name: 'access_profile' }
    }).then((resData) => {
      setIsButtonLoading(false);
      setIsLoading(false);
      notification.success({
        message: 'Yeah!',
        description: values.access_profile ? 'A aplicação já tem acesso ao perfil' : 'A aplicação já não tem acesso ao perfil'
      })
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    });
  }

  function handleChangePrivateAccess(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    axios.post(endpoints.appSettings.update, {
      data: { values: values, name: 'close_app' }
    }).then((resData) => {
      setIsButtonLoading(false);
      setIsLoading(false);
      notification.success({
        message: 'Yeah!',
        description: values.private_acess ? 'A aplicação está aberta!' : 'Aplicação está fechada!'
      })
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    });
  }

  return (
    <div className='dashboard_page'>
      <Row className='title_row mb-20 dashboard_title_row'>
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Definições</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Form
            form={formHalfAccess}
            className="mr-20"
            id="add-commission_type-form"
            layout="horizontal"
            onFinish={handleChangeHalfAccess}
          >
            <Form.Item
              className="m-0"
              name="access_profile"
              label="Dar acesso ao perfil"
              valuePropName='checked'
            >
              <Switch
                disabled={isButtonLoading}
                onChange={formHalfAccess.submit}
                size='large'
              />
            </Form.Item>
          </Form>
          <Form
            form={form}
            id="add-commission_type-form"
            layout="horizontal"
            onFinish={handleChangePrivateAccess}
          >
            <Form.Item
              className="m-0"
              name="close_app"
              label="Fechar aplicação"
              valuePropName='checked'
            >
              <Switch
                disabled={isButtonLoading}
                onChange={form.submit}
                size='large'
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            <Tabs
              type="card"
              items={[{
                label: `Versão`,
                key: '1',
                children: <SettingsVersion data={settingsApp} />,
              }, {
                label: `Dias`,
                key: '2',
                children: <SettingsDays data={settingsEvent} />,
              }, {
                label: `Salas`,
                key: '3',
                children: <SettingsRooms data={settingsEvent} />,
              }, {
                label: `Sponsor`,
                key: '4',
                children: <SettingsSponsor data={settingsApp} />,
              }]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Settings;
