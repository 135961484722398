import { Row, Col, Button, Spin, Divider, Empty } from "antd";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../../utils/config";
import Create from "../../components/CommissionType/Create";
import Update from "../../components/CommissionType/Update";
import endpoints from "../../utils/endpoints";

export default function CommissionType() {
  const [commissionType, setCommissionType] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedCommissionType, setSelectedCommissionType] = useState({});
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetCommissionType();
  }, []);

  function handleGetCommissionType() {
    setIsLoading(true);
    axios
      .get(endpoints.commissionType.read)
      .then((response) => {
        let commissionTypeData = [];
        for (let i = 0; i < response.data.length; i++) {
          let arrayData = response.data[i];
          let aux = {
            key: i + 1,
            number: i + 1,
            name: arrayData.name,
            actions: (
              <div className="actions-buttons-div">
                <EditOutlined
                  className="edit-action"
                  onClick={() => handleOpenModalEditCommission(arrayData)}
                />
              </div>
            ),
          };

          commissionTypeData.push(aux);
        }

        setCommissionType(response.data);
        setTableData(commissionTypeData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleOpenModalAddCommission() {
    setIsOpenAdd(true);
  }

  function handleCloseModalAddCommission() {
    setIsOpenAdd(false);
    handleGetCommissionType();
  }

  function handleOpenModalEditCommission(data) {
    setSelectedCommissionType(data);
    setIsOpenEdit(true);
  }

  function handleCloseModalEditCommission() {
    setIsOpenEdit(false);
    handleGetCommissionType();
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenAdd} close={handleCloseModalAddCommission} />
      <Update
        data={selectedCommissionType}
        open={isOpenEdit}
        close={handleCloseModalEditCommission}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={10} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Tipo de Comissão</h3>
        </Col>
        <Col span={14} className="d-flex jc-end ai-center">
          <Button
            type="primary"
            size="large"
            onClick={handleOpenModalAddCommission}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={22}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
