import { Row, Col, Button, Divider, Pagination, Spin, Empty } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import Search from "antd/es/input/Search";

import config from "../../utils/config";
import Create from "../../components/Poster/Create";
import Update from "../../components/Poster/Update";
import Delete from "../../components/Poster/Delete";

import "./Posters.css";
import endpoints from "../../utils/endpoints";

export default function Posters() {
  const [posters, setPosters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedPoster, setSelectedPoster] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  useEffect(() => {
    handleGetPosters();
  }, []);

  function handleGetPosters() {
    setIsLoading(true);
    axios
      .get(endpoints.poster.read)
      .then((response) => {
        setPosters(response.data);
        handlePrepareData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let auxArray = [];
    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];
      let aux = {
        key: i,
        number: i + 1,
        name: arrayData.name,
        link: (
          <LinkOutlined
            className="link-action"
            onClick={() => window.open(arrayData.link)}
          />
        ),
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined
              className="edit-action"
              onClick={() => handleOpenModalEditPoster(arrayData)}
            />
            <DeleteOutlined
              className="delete-action"
              onClick={() => handleOpenModalDeletePoster(arrayData)}
            />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenModalAddPoster() {
    setIsOpenAdd(true);
  }

  function handleCloseModalAddPoster() {
    setIsOpenAdd(false);
    handleGetPosters();
  }

  function handleOpenModalEditPoster(data) {
    setSelectedPoster(data);
    setIsOpenEdit(true);
  }

  function handleCloseModalEditPoster() {
    setSelectedPoster({});
    setIsOpenEdit(false);
    handleGetPosters();
  }

  function handleOpenModalDeletePoster(data) {
    setSelectedPoster(data);
    setIsOpenDelete(true);
  }

  function handleCloseModalDeletePoster(data) {
    setSelectedPoster({});
    setIsOpenDelete(false);
    handleGetPosters();
  }

  function handleSearch(e) {
    let value = e.target.value;
    setSearchText(value);
    const newData = posters.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    handlePrepareData(newData);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenAdd} close={handleCloseModalAddPoster} />
      <Update
        data={selectedPoster}
        open={isOpenEdit}
        close={handleCloseModalEditPoster}
      />
      <Delete
        data={selectedPoster}
        open={isOpenDelete}
        close={handleCloseModalDeletePoster}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={16} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Posters</h3>
        </Col>
        <Col span={8} className="d-flex jc-end ai-center">
          <Button
            type="primary"
            size="large"
            onClick={handleOpenModalAddPoster}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={14}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={4} className="d-flex jc-center ai-center">
                          {item.type}
                        </Col>
                        <Col span={4} className="d-flex jc-center ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Link</span>
                            <span className="d-flex ai-center">
                              {item.link}
                            </span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={posters.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
