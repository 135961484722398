import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Drawer,
  Row,
  Select,
  Upload,
  Input,
  Spin,
  Table
} from 'antd';
import axios from 'axios';
import { InboxOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useForm } from "antd/es/form/Form";

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

const { Dragger } = Upload;

function Create({ open, close }) {

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [usersToImport, setUsersToImport] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [results, setResults] = useState({});
  const [tableDataResults, setTableDataResults] = useState([]);

  const [form] = useForm();

  const uploadProps = {
    accept: '.csv',
    name: 'file',
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      console.log(newFileList);
      setFileList(newFileList);
      setTableColumns([]);
      setTableData([]);
      setUsersToImport([]);
    },
    beforeUpload: (file) => {
      console.log(file);
      setFileList([file]);
      handleReadFile(file);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    fileList,
    defaultFileList: []
  };

  function handleReadFile(file) {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('file', file);

    fetch(`${config.server_ip}${endpoints.user.readFileImport}`, {
      method: "POST",
      body: formData
    }).then((res) => {
      res.json().then((responseData) => {

        let auxTableData = [];

        for (let i = 0; i < responseData.length; i++) {
          auxTableData.push(responseData[i]);
        }
        console.log(auxTableData)

        let tableColumnsArray = Object.keys(responseData[0]);
        let auxTableColumns = [];

        for (let z = 0; z < tableColumnsArray.length; z++) {
          auxTableColumns.push({
            title: tableColumnsArray[z],
            dataIndex: tableColumnsArray[z],
            key: z,
            width: '50%'
          })
        }

        setTableData(auxTableData);
        setTableColumns(auxTableColumns);
        setUsersToImport(responseData);
        setIsLoading(false);
      }).catch(e => {
        console.log(e);
        setIsLoading(false);
      })
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }

  function handleClose() {
    close();
    setFileList([]);
    setUsersToImport([]);
    setShowResult(false);
    setResults({});
    form.resetFields();
  }

  async function handleSubmit(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    let inserted_users = [];
    let updated_users = [];

    let limit_bulk = values.limit_bulk ? values.limit_bulk : usersToImport.length;
    let auxUsersToImport = usersToImport.slice(0, limit_bulk);
    let count = 0;

    handleManageImportData(auxUsersToImport, limit_bulk, count, inserted_users, updated_users).then((res) => {
      console.log(res);
      let auxTableData = [];

      for (let i = 0; i < res.inserted_users.length; i++) {
        auxTableData.push({ ...res.inserted_users[i], status: 'inserted' })
      }

      for (let i = 0; i < res.updated_users.length; i++) {
        auxTableData.push({ ...res.updated_users[i], status: 'updated' })
      }

      let aux = {
        data: auxTableData,
        inserted_users: res.inserted_users,
        updated_users: res.updated_users
      }

      console.log(aux)
      setTableDataResults(auxTableData);
      setShowResult(true);
      setResults(aux);
      setIsLoading(false);
      setIsButtonLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
      setIsButtonLoading(false);
    })
  }

  async function handleManageImportData(data, limit_bulk, count, inserted_users, updated_users) {
    return handleImport(data).then((importedData) => {

      inserted_users.push(...importedData.data.inserted_users);
      updated_users.push(...importedData.data.updated_users);

      count++;
      let auxUsersToImport = usersToImport.slice((count * limit_bulk), ((limit_bulk * count) + limit_bulk));

      if (auxUsersToImport.length > 0) {
        return handleManageImportData(auxUsersToImport, limit_bulk, count, inserted_users, updated_users,);
      } else {
        return { total_users: usersToImport.length, inserted_users, updated_users };
      }
    })
  }

  function handleImport(e) {
    return new Promise((resolve, reject) => {
      axios.post(endpoints.user.import, {
        data: { users: e }
      }).then((res) => {
        resolve(res);
      }).catch((error) => {
        reject(error)
      })
    })
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Importar utilizadores"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
    >

      <Spin spinning={isLoading} tip="Uploading...">

        {showResult ?
          <Col span={24}>
            <p className="f-26 bold text-center">Utilizadores importados com sucesso!</p>
            <Row gutter={[24]} className="mt-30">
              <Col span={8}>
                <p className="f-20 text-center">Total de utilizadores:</p>
                <p className="f-26 bold text-center">{results.data.length}</p>
              </Col>
              <Col span={8}>
                <p className="f-20 text-center">Success:</p>
                <p className="f-26 bold text-center">{results.updated_users.length}</p>
              </Col>
              <Col span={8}>
                <p className="f-20 text-center">Updated:</p>
                <p className="f-26 bold text-center">{results.inserted_users.length}</p>
              </Col>
            </Row>
            <Row>
              <Table
                className="mt-40 w-100"
                columns={[
                  {
                    title: 'Nome',
                    dataIndex: 'name',
                  },
                  {
                    title: 'E-mail',
                    dataIndex: 'email',
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    filters: [
                      {
                        text: 'Success',
                        value: 'inserted',
                      },
                      {
                        text: 'Updated',
                        value: 'updated',
                      },
                    ],
                    onFilter: (value, record) => results.data.filter(item => item.status === value)[0],
                  },
                ]}
                dataSource={results.data.map((item) => {
                  console.log(item);
                  return {
                    name: item.name,
                    email: item.email,
                    status: item.status === 'inserted' ? <CheckCircleOutlined className="green f-16" /> : <InfoCircleOutlined className="red f-16" />
                  }
                })}
                loading={isTableLoading}
              />
            </Row>
          </Col>
          :
          <>
            <Dragger {...uploadProps} style={{ maxHeight: 200 }} className="import_users_dragger">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            {usersToImport.length > 0 &&
              <Row className="import_users_info">
                <Col span={24}>
                  <div className="f-20 mt-10 text-center">Nº de utilizadores a importar:</div>
                  <div className="f-36 mb-40 text-center">{usersToImport.length}</div>
                  <Row className="w-100">
                    <Col span={24}>
                      <Table
                        className="import_users_table"
                        columns={tableColumns}
                        dataSource={tableData}
                        loading={isTableLoading}
                        scroll={{ x: 1 }}
                      />
                      <Form
                        form={form}
                        id="form-import-users"
                        onFinish={handleSubmit}
                        initialValues={{
                          price: [{}],
                          options: [{}]
                        }}
                        className='form w-100'
                        autoComplete="off"
                        layout="vertical"
                      >
                        <Form.Item label="Limit bulk" name="limit_bulk">
                          <InputNumber className="w-100" size="large" placeholder="0" />
                        </Form.Item>
                        {!showResult &&
                          <Button type="primary" className='submit' onClick={form.submit} loading={isButtonLoading}>
                            Importar utilizadores
                          </Button>
                        }
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </>
        }
      </Spin>
    </Drawer>
  );
};

export default Create;