import { Row, Col, Button, Divider, Spin, Empty } from "antd";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../../utils/config";
import Create from "../../components/SponsorType/Create";
import Update from "../../components/SponsorType/Update";

import "./SponsorsType.css";
import endpoints from "../../utils/endpoints";

function SponsorsType() {
  const [sponsorsType, setSponsorsType] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedSponsorType, setSelectedSponsorType] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetSponsorsType();
  }, []);

  function handleGetSponsorsType() {
    setIsLoading(true);
    axios
      .get(endpoints.sponsorType.read)
      .then((response) => {
        let sponsorsTypeData = [];
        for (let i = 0; i < response.data.length; i++) {
          let arrayData = response.data[i];
          let aux = {
            key: i,
            number: i + 1,
            name: arrayData.name,
            actions: (
              <div className="actions-buttons-div">
                <EditOutlined
                  className="edit-action"
                  onClick={() => handleOpenModalEditSponsorType(arrayData)}
                />
              </div>
            ),
          };

          sponsorsTypeData.push(aux);
        }

        setSponsorsType(response.data);
        setTableData(sponsorsTypeData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleOpenModalAddSponsorType() {
    setIsOpenAdd(true);
  }

  function handleCloseModalAddSponsorType() {
    setIsOpenAdd(false);
    handleGetSponsorsType();
  }

  function handleOpenModalEditSponsorType(data) {
    setSelectedSponsorType(data);
    setIsOpenEdit(true);
  }

  function handleCloseModalEditSponsorType() {
    setSelectedSponsorType({});
    setIsOpenEdit(false);
    handleGetSponsorsType();
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenAdd} close={handleCloseModalAddSponsorType} />
      <Update
        data={selectedSponsorType}
        open={isOpenEdit}
        close={handleCloseModalEditSponsorType}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Tipos de patrocinador</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Button
            type="primary"
            size="large"
            onClick={handleOpenModalAddSponsorType}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              tableData.map((item) => {
                return (
                  <Row className="table_item">
                    <Col span={1}>
                      <div className="d-flex jc-center ai-center mr-20">
                        {item.number}
                      </div>
                    </Col>
                    <Col span={21}>
                      <div className="d-flex flex-column">
                        <span className="f-12">Nome</span>
                        <span className="f-18 semi-bold">{item.name}</span>
                      </div>
                    </Col>
                    <Col span={2} className="d-flex jc-end ai-center">
                      {item.actions}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default SponsorsType;
