import {
  Row,
  Col,
  Button,
  Input,
  Radio,
  Form,
  notification,
  Drawer,
  Upload,
  Select,
  InputNumber,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

const { Dragger } = Upload;

export default function Create({ open, close, sponsorsType }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    fileList.forEach((file) => {
      if (file.uid !== "not_new") {
        formData.append("file", file);
      }
    });

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.sponsor.create}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        close();
        setFilePreview("");
        setFileList([]);
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Yeah!",
          description: "Patrocinador foi adicionado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleFilterSponsorPosition(e) {
    console.log("hii");
  }

  return (
    <Drawer
      width={800}
      className="drawer-antd-sponsor"
      title={`Adicionar patrocinador`}
      open={open}
      onClose={close}
      maskClosable={false}
      footer={[
        <Button
          className="mr-20"
          size="large"
          type="primary"
          onClick={form.submit}
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Adicionar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="add-speaker-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Form.Item
          name="img"
          label="Imagem"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Este é um campo obrigatório" }]}
        >
          <Dragger
            {...uploadProps}
            className={`dragger ${filePreview ? "hasPreview" : ""}`}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <div
              className={`preview_file ${filePreview ? "hasPreview" : ""}`}
              style={{
                backgroundImage: `url(${
                  filePreview && filePreview.includes("base64")
                    ? filePreview
                    : `${config.server_ip}media/${filePreview}`
                })`,
              }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: "Este é um campo obrigatório" }]}
        >
          <Input size="large" placeholder="Nome..." />
        </Form.Item>
        <Form.Item
          name="type_id"
          label="Tipo"
          rules={[{ required: true, message: "Este é um campo obrigatório" }]}
        >
          <Select
            size="large"
            placeholder="Tipo de patrocinador..."
            onChange={(e) => handleFilterSponsorPosition(e)}
            options={sponsorsType.map((value, index) => ({
              value: value.id,
              label: value.name,
            }))}
          ></Select>
        </Form.Item>
        <Form.Item
          name="pos"
          label="Posição (nº)"
          rules={[{ required: true, message: "Este é um campo obrigatório" }]}
        >
          <InputNumber size="large" min={1} />
        </Form.Item>
        <Form.Item
          name="link"
          label="Link"
          rules={[{ required: true, message: "Este é um campo obrigatório" }]}
        >
          <Input size="large" placeholder="Link..." />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
