import { Row, Col, Button, Spin, Divider, Pagination, Empty } from "antd";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../../utils/config";
import Create from "../../components/CommissionPosition/Create";
import Update from "../../components/CommissionPosition/Update";

import "./CommissionPosition.css";
import endpoints from "../../utils/endpoints";

export default function CommissionPosition() {
  const [commissionPosition, setCommissionPosition] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedCommissionPosition, setSelectedCommissionPosition] = useState(
    {}
  );
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  useEffect(() => {
    handleGetCommissionPosition(currentPage);
  }, []);

  function handleGetCommissionPosition(page) {
    setIsLoading(true);
    axios
      .get(endpoints.commissionPosition.read)
      .then((response) => {
        let commissionPositionData = [];
        for (let i = 0; i < response.data.length; i++) {
          let arrayData = response.data[i];

          let aux = {
            key: i,
            number: i + 1,
            name: arrayData.name,
            actions: (
              <div className="actions-buttons-div">
                <EditOutlined
                  className="edit-action"
                  onClick={() =>
                    handleOpenModalEditCommissionPosition(arrayData)
                  }
                />
              </div>
            ),
          };

          commissionPositionData.push(aux);
        }

        setCommissionPosition(response.data);
        setTableData(commissionPositionData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenModalAddCommissionPosition() {
    setIsOpenAdd(true);
  }

  function handleCloseModalAddCommissionPosition() {
    setIsOpenAdd(false);
    handleGetCommissionPosition();
  }

  function handleOpenModalEditCommissionPosition(data) {
    setSelectedCommissionPosition(data);
    setIsOpenEdit(true);
  }

  function handleCloseModalEditCommissionPosition() {
    setIsOpenEdit(false);
    handleGetCommissionPosition();
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenAdd} close={handleCloseModalAddCommissionPosition} />
      <Update
        data={selectedCommissionPosition}
        open={isOpenEdit}
        close={handleCloseModalEditCommissionPosition}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={10} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Posições da Comissão</h3>
        </Col>
        <Col span={14} className="d-flex jc-end ai-center">
          <Button
            type="primary"
            size="large"
            onClick={handleOpenModalAddCommissionPosition}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={22}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={commissionPosition.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
