import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfigProvider, Spin } from "antd";
import axios from "axios";

import Home from "./pages/Home/Home";
import Program from "./pages/Program/Program.js";
import Faculty from "./pages/Faculty/Faculty";
import Commission from "./pages/Commission/Commission";
import Sponsors from "./pages/Sponsors/Sponsors";
import ScientificSponsor from "./pages/ScientificSponsor/ScientificSponsor";
import SponsorsType from "./pages/SponsorsType/SponsorsType";
import Login from "./pages/Login/Login";
import Posters from "./pages/Posters/Posters";
import WelcomeLetter from "./pages/WelcomeLetter/WelcomeLetter";
import CommissionType from "./pages/CommissionType/CommissionType";
import CommissionPosition from "./pages/CommissionPosition/CommissionPosition";
import OralCommunications from "./pages/OralCommunications/OralCommunications";
import Notifications from "./pages/Notifications/Notifications";
import Settings from "./pages/Settings/Settings";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Main from "./layout/Main/Main";

import api from "./utils/api.js";

import "./assets/styles/global.css";
import "./index.css";
import Users from "./pages/Users/Users";
import InstitutionalSponsor from "./pages/InstitutionalSponsor/InstitutionalSponsor.js";
import SponsorBanners from "./pages/SponsorBanners/SponsorBanners.js";

api.axiosCreate();

function App() {
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#E3C62B",
          fontFamily: "Poppins",
        },
      }}
    >
      <div
        className={`App ${
          window.location.pathname.includes("/admin") ? "admin" : ""
        }`}
      >
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route element={<Main handleLogout={handleLogout} />}>
            <Route
              index
              exact
              path="/"
              element={<Navigate to={`/program`} replace />}
            />
            <Route
              exact
              path="/login"
              element={<Navigate to={`/program`} replace />}
            />
            <Route exact path="/dashboard" element={<Home />} />
            <Route exact path="/program" element={<Program />} />
            <Route exact path="/faculty" element={<Faculty />} />
            <Route exact path="/commission" element={<Commission />} />
            <Route exact path="/commission-type" element={<CommissionType />} />
            <Route
              exact
              path="/commission-position"
              element={<CommissionPosition />}
            />
            <Route exact path="/sponsors" element={<Sponsors />} />
            <Route
              exact
              path="/scientific-sponsors"
              element={<ScientificSponsor />}
            />
            <Route exact path="/sponsor-banners" element={<SponsorBanners />} />
            <Route
              exact
              path="/institutional-sponsors"
              element={<InstitutionalSponsor />}
            />
            <Route exact path="/sponsors-type" element={<SponsorsType />} />
            <Route exact path="/posters" element={<Posters />} />
            <Route
              exact
              path="/oral-communications"
              element={<OralCommunications />}
            />
            <Route exact path="/welcome-letter" element={<WelcomeLetter />} />
            <Route exact path="/notifications" element={<Notifications />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/users" element={<Users />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
