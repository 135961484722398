import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Spin,
  message,
} from "antd";

import config from "../../utils/config";

function Home() {

  const [playStoreData, setPlayStoreData] = useState({});
  const [appStoreData, setAppStoreData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [lastMonthData, setLastMonthData] = useState({});
  const [percentageData, setPercentageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('HI')
    setIsLoading(false)
  }, []);

  return (
    <>
      {isLoading ?
        <div className="layout-content">
          <Spin size="large" />
        </div>
        :
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
          </Row>
        </div>
      }
    </>
  );
}

export default Home;
