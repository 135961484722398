import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Radio,
  Form,
  notification,
  Drawer,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import config from "../../utils/config.js";

import "react-quill/dist/quill.snow.css";
import {
  DeleteOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CreateFacuty from "../Faculty/Create.js";
import endpoints from "../../utils/endpoints.js";

const { Option } = Select;
const { RangePicker } = TimePicker;

function Create({ open, close, faculty, eventSettings }) {
  const [eventDays, setEventDays] = useState([]);
  const [eventRooms, setEventRooms] = useState([]);
  const [facultyData, setFacultyData] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenCreateFaculty, setIsOpenCreateFaculty] = useState(false);

  const defaultFormList = {
    description: [{ themes: [], speakers: [] }],
    mods: [],
    add_to_calendar: true,
    has_description: true,
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      setEventDays(
        JSON.parse(
          eventSettings.filter((item) => item.name === "event_days")[0].json
        ).event_days
      );
      setEventRooms(
        JSON.parse(
          eventSettings.filter((item) => item.name === "event_rooms")[0].json
        ).event_rooms
      );
      setFacultyData(faculty);
    }
  }, [open]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let speakersAux = [];
    if (values.has_description) {
      let auxDescription = values.description;
      for (let i = 0; i < auxDescription.length; i++) {
        let auxSpeakersArray = auxDescription[i].speakers;
        if (auxSpeakersArray) {
          for (let z = 0; z < auxSpeakersArray.length; z++) {
            if (!speakersAux.includes(auxSpeakersArray[z])) {
              speakersAux.push(auxSpeakersArray[z]);
            }
          }
        } else {
          auxDescription[i].speakers = [];
        }
      }
    }

    let obj = {
      title: values.title,
      has_description: values.has_description,
      description: JSON.stringify(values.description),
      local: values.local,
      date: values.date,
      hour_start: dayjs(values.duration[0]).format("HH:mm:ss"),
      hour_end: dayjs(values.duration[1]).format("HH:mm:ss"),
      mods: JSON.stringify(values.mods),
      speakers: JSON.stringify(speakersAux),
      type: values.type,
      hide_local: values.hide_local ? 1 : 0,
      add_to_calendar: values.add_to_calendar ? 1 : 0,
      aha_slides: values.aha_slides ? 1 : 0,
      aha_slides_active: values.aha_slides_active ? 1 : 0,
    };

    axios
      .post(endpoints.program.create, {
        data: obj,
      })
      .then((res) => {
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
          form.resetFields();
          notification.success({
            message: "Yeah!",
            description: "Programa foi adicionado com sucesso!",
          });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleClose() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  function handleOpenCreateFaculty() {
    setIsOpenCreateFaculty(true);
  }

  function handleCloseCreateFaculty(data) {
    if (data) {
      let auxFaculty = JSON.parse(JSON.stringify(faculty));

      auxFaculty.push({ id: data.id, name: data.name });
      auxFaculty.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setFacultyData(auxFaculty);
    }
    setIsOpenCreateFaculty(false);
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  return (
    <Drawer
      width={800}
      title={`Adicionar novo programa`}
      open={open}
      onClose={handleClose}
      maskClosable={false}
      extra={[
        <Button
          className="mr-20"
          size="large"
          type="primary"
          onClick={form.submit}
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Adicionar
        </Button>,
        <Button size="large" onClick={handleClose}>
          Cancelar
        </Button>,
      ]}
    >
      <CreateFacuty
        open={isOpenCreateFaculty}
        close={handleCloseCreateFaculty}
      />
      <Form
        form={form}
        id="add-program-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
        initialValues={defaultFormList}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="title"
              label="Título"
              rules={[
                { required: true, message: "Este é um campo obrigatório" },
              ]}
            >
              <ReactQuill
                placeholder="Título"
                className="title_react_quill"
                theme="snow"
              />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={22}>
                <Form.Item name="mods" label="Moderadores">
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Selecione..."
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={facultyData.map((value, index) => ({
                      label: value.name,
                      value: value.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={2} className="d-flex jc-center ai-center">
                <PlusCircleOutlined
                  className="icon_add"
                  onClick={handleOpenCreateFaculty}
                />
              </Col>
            </Row>
            <Form.Item
              name="has_description"
              label="Contém temas ou palestrantes?"
              valuePropName="checked"
            >
              <Switch defaultChecked size="large" />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.has_description !== currentValues.has_description
              }
            >
              {({ getFieldValue }) => {
                let has_description = getFieldValue("has_description");
                if (has_description) {
                  return (
                    <Form.List name="description" label="Descrição">
                      {(fields, { add, remove }) => (
                        <Row gutter={[24]}>
                          <Col span={24}>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <Row>
                                  <Col
                                    span={24}
                                    className={`${
                                      fields.length > 1 && index > 0
                                        ? "mt-40"
                                        : ""
                                    }`}
                                  >
                                    <Row
                                      className={`mb-30 ${
                                        has_description ? "" : "disabled"
                                      }`}
                                    >
                                      {fields.length > 1 && (
                                        <DeleteOutlined
                                          onClick={() => remove(name)}
                                          style={{
                                            fontSize: "20px",
                                            position: "absolute",
                                            top: 10,
                                            right: 0,
                                            zIndex: 2,
                                          }}
                                        />
                                      )}
                                      <Col span={24}>
                                        <Form.Item
                                          {...restField}
                                          label="Temas"
                                          name={[name, "themes"]}
                                        >
                                          <ReactQuill
                                            readOnly={!has_description}
                                            placeholder="Tema..."
                                            className="description_react_quill"
                                            theme="snow"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={24}>
                                        <Row>
                                          <Col span={22}>
                                            <Form.Item
                                              className="mb-0"
                                              label="Palestrantes"
                                              name={[name, "speakers"]}
                                            >
                                              <Select
                                                disabled={!has_description}
                                                showSearch
                                                allowClear
                                                key={"palestrantes"}
                                                mode="multiple"
                                                size="large"
                                                style={{ width: "100%" }}
                                                placeholder="Selecione..."
                                                filterOption={(input, option) =>
                                                  (option?.children ?? "")
                                                    .toLowerCase()
                                                    .includes(
                                                      input.toLowerCase()
                                                    )
                                                }
                                                options={facultyData.map(
                                                  (value, index) => ({
                                                    label: value.name,
                                                    value: value.id,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col
                                            span={2}
                                            className="d-flex jc-center ai-center"
                                          >
                                            <PlusCircleOutlined
                                              className="icon_add mt-24"
                                              onClick={handleOpenCreateFaculty}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )
                            )}
                          </Col>

                          <Col
                            span={24}
                            className="mb-24 d-flex jc-center ai-center mt-20"
                          >
                            <Button
                              disabled={!has_description}
                              size="large"
                              onClick={() => add()}
                            >
                              <PlusCircleOutlined /> Adicionar
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Form.List>
                  );
                }
              }}
            </Form.Item>
            <Form.Item name="local" label="Local">
              <Select
                showSearch
                allowClear
                size="large"
                style={{ width: "100%" }}
                placeholder="Selecione..."
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={eventRooms.map((item, index) => ({
                  label: item.name,
                  value: item.name,
                }))}
              />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item
                  name="date"
                  label="Data"
                  rules={[
                    { required: true, message: "Este é um campo obrigatório" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Selecione..."
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={eventDays.map((value, index) => ({
                      label: dayjs(value.day).format("YYYY-MM-DD"),
                      value: dayjs(value.day).format("YYYY-MM-DD"),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="duration"
                  label="Duração"
                  rules={[
                    { required: true, message: "Este é um campo obrigatório" },
                  ]}
                >
                  <RangePicker
                    format={"HH:mm"}
                    key={"hora"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="hide_local"
              label="Esconder sala"
              valuePropName="checked"
              tooltip="Esconder sala da página do programa"
            >
              <Switch size="large" />
            </Form.Item>
            <Form.Item
              name="add_to_calendar"
              label="Adicionar ao calendário?"
              valuePropName="checked"
              tooltip="Permitir que o utilizador adicione ao calendário"
            >
              <Switch defaultChecked size="large" />
            </Form.Item>

            <Row gutter={[24]}>
              <Col span={6}>
                <Form.Item
                  name="aha_slides"
                  label="AHA Slides"
                  valuePropName="checked"
                  tooltip="Adicionar link de AHA Slides a este elemento do programa"
                >
                  <Switch size="large" />
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.aha_slides !== currentValues.aha_slides
                }
              >
                {({ getFieldValue }) => {
                  if (getFieldValue("aha_slides")) {
                    return (
                      <Col span={6}>
                        <Form.Item
                          name="aha_slides_active"
                          label="AHA Slides Ativo"
                          valuePropName="checked"
                          tooltip="Ativar botão de AHA Slides para o utilizador"
                        >
                          <Switch
                            disabled={!getFieldValue("aha_slides")}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                }}
              </Form.Item>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.aha_slides !== currentValues.aha_slides
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("aha_slides")) {
                  return (
                    <Row gutter={[24]}>
                      <Col span={12}>
                        <Form.Item
                          name="button_name"
                          label="AHA Slides botão"
                          tooltip="Nome do botão para o AHA Slides"
                        >
                          <Input
                            disabled={!getFieldValue("aha_slides")}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="button_link"
                          label="AHA Slides link"
                          tooltip="Link do botão para o AHA Slides"
                        >
                          <Input
                            disabled={!getFieldValue("aha_slides")}
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Create;
