import { Row, Col, Modal, Button, Input, Form, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config.js";

import { DeleteOutlined } from "@ant-design/icons";
import endpoints from "../../utils/endpoints.js";

export default function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleDelete() {
    setIsButtonLoading(true);

    axios
      .post(endpoints.institutionalSponsor.delete, {
        data: { id: data.id },
      })
      .then((resData) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Yeah!",
          description: "Patrocinador institucional foi apagado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-commission"
      title={`Apagar patrocinador científico`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button
          danger
          icon={<DeleteOutlined />}
          size="large"
          type="primary"
          onClick={handleDelete}
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Apagar
        </Button>,
        <Button size="large" onClicdk={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <p>Tem a certeza que quer apagar o patrocinador científico:</p>
          <p className="bold f-18">{data.name}</p>
        </Col>
      </Row>
    </Modal>
  );
}
