import {
  Row,
  Col,
  Button,
  Input,
  Form,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';
import config from "../../utils/config.js";

import "./Settings.css";
import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";
import Dragger from "antd/es/upload/Dragger.js";
import { InboxOutlined } from "@ant-design/icons";

function SettingsSponsor({ data }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState('');

  const [form] = Form.useForm();

  const uploadProps = {
    name: 'file',
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'image');
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  useEffect(() => {
    if (data.length > 0) {
      let sponsorData = JSON.parse(data.filter((item) => item.name === "sponsor")[0].json);
      console.log(sponsorData)
      if (sponsorData.img) {
        setFileList([{ id: 'not_new', name: sponsorData.img }]);
        setFilePreview(sponsorData.img)
        sponsorData.img = [{ id: 'not_new', name: sponsorData.img }]
      }
      form.setFieldsValue(sponsorData);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let auxData = { values: { img: null }, name: 'sponsor' }

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append('file', file);
      });
    } else {
      auxData.values.img = null;
    }

    auxData.name = "sponsor";
    formData.append('data', JSON.stringify(auxData));

    fetch(`${config.server_ip}${endpoints.appSettings.update}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
        setIsButtonLoading(false);
        notification.success({
          message: 'Yeah!',
          description: 'Definições foram editadas com sucesso!'
        })
      }).catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: 'Oops!',
          description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
        })
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  function normFile(e) {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview)
  }


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <div className='dashboard_page'>
      <Row className="dashboard_content_row">
        <Col span={24}>
          <Form
            form={form}
            id="add-commission_type-form"
            layout="vertical"
            onFinish={handleSubmit}
            onFinishFailed={handleWarningMissingFields}
          >
            <Form.Item
              name="img"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Dragger {...uploadProps} className={`user-img-dragger dragger ${filePreview ? 'hasPreview' : ''}`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <div
                  className={`preview_file ${filePreview ? 'hasPreview' : ''}`}
                  style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}media/${filePreview}`})` }}
                >
                </div>
              </Dragger>
            </Form.Item>
            <Row>
              <Col span={24}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsSponsor;
