import {
  Row,
  Col,
  Button,
  Form,
  notification,
  DatePicker,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';

import endpoints from "../../utils/endpoints.js";
import "./Settings.css";
import { DeleteOutlined } from "@ant-design/icons";

function SettingsRooms({ data }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data.length > 0) {
      let eventRooms = data.filter((item) => item.name === "event_rooms")[0];
      eventRooms = JSON.parse(eventRooms.json);

      form.setFieldsValue(eventRooms);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);

    console.log(values);
    axios.post(endpoints.eventSettings.update, {
      data: { values: values, name: 'event_rooms' }
    }).then((resData) => {
      setIsButtonLoading(false);
      notification.success({
        message: 'Sucesso!',
        description: 'Definições foram editadas com sucesso!'
      })
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  return (
    <div className='dashboard_page'>
      <Row className="dashboard_content_row">
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            onFinishFailed={handleWarningMissingFields}
            initialValues={{ event_rooms: [{}] }}
          >
            <p>Salas</p>
            <Form.List name="event_rooms" label="Descrição">
              {(fields, { add, remove }) => (
                <Row gutter={[24]}>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Col span={6}>
                      <Row gutter={[24]}>
                        <Col span={fields.length > 1 ? 20 : 24}>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                          >
                            <Input className="w-100" size="large" />
                          </Form.Item>
                        </Col>
                        {fields.length > 1 &&
                          <Col span={4}>
                            <Button size="large" type="dashed" danger onClick={() => remove(name)}><DeleteOutlined /></Button>
                          </Col>
                        }
                      </Row>
                    </Col>
                  ))}
                  <Col span={24} className="mb-20">
                    <Button size="large" type="dashed" onClick={() => add()}>Adicionar sala</Button>
                  </Col>
                </Row>)}
            </Form.List>

            <Row gutter={[24]}>
              <Col span={6}>
                <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsRooms;
