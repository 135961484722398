const endpoints = {
  auth: {
    login: "auth/login",
    register: "auth/register",
    verifyUser: "auth/verifyUser",
    verifyToken: "auth/verifyToken",
    renewToken: "auth/renewToken",
    resetPasswordEmail: "auth/resetPasswordEmail",
    resetPassword: "auth/resetPassword",
  },
  commission: {
    read: "commission/read",
    create: "commission/create",
    update: "commission/update",
    delete: "commission/delete",
  },
  commissionPosition: {
    read: "commissionPosition/read",
    create: "commissionPosition/create",
    update: "commissionPosition/update",
    delete: "commissionPosition/delete",
  },
  commissionType: {
    read: "commissionType/read",
    create: "commissionType/create",
    update: "commissionType/update",
    delete: "commissionType/delete",
  },
  sponsor: {
    read: "sponsor/read",
    create: "sponsor/create",
    update: "sponsor/update",
    delete: "sponsor/delete",
  },
  sponsorType: {
    read: "sponsorType/read",
    create: "sponsorType/create",
    update: "sponsorType/update",
    delete: "sponsorType/delete",
  },
  scientificSponsor: {
    read: "scientificSponsor/read",
    create: "scientificSponsor/create",
    update: "scientificSponsor/update",
    delete: "scientificSponsor/delete",
  },
  institutionalSponsor: {
    read: "institutionalSponsor/read",
    create: "institutionalSponsor/create",
    update: "institutionalSponsor/update",
    delete: "institutionalSponsor/delete",
  },
  sponsorBanners: {
    read: "sponsorBanner/read",
    create: "sponsorBanner/create",
    update: "sponsorBanner/update",
    delete: "sponsorBanner/delete",
  },
  poster: {
    read: "poster/read",
    create: "poster/create",
    update: "poster/update",
    delete: "poster/delete",
  },
  communication: {
    read: "communication/read",
    create: "communication/create",
    update: "communication/update",
    delete: "communication/delete",
  },
  program: {
    read: "program/read",
    readyById: "program/readById",
    create: "program/create",
    update: "program/update",
    delete: "program/delete",
    rate: "program/rate",
  },
  speaker: {
    read: "speaker/read",
    create: "speaker/create",
    update: "speaker/update",
    delete: "speaker/delete",
  },
  faculty: {
    read: "faculty/read",
    create: "faculty/create",
    update: "faculty/update",
    delete: "faculty/delete",
  },
  moderator: {
    read: "moderator/read",
    create: "moderator/create",
    update: "moderator/update",
    delete: "moderator/delete",
  },
  notification: {
    read: "notification/read",
    readById: "notification/readyById",
    create: "notification/create",
    send: "notification/send",
    update: "notification/update",
    delete: "notification/delete",
  },
  user: {
    create: "user/create",
    read: "user/read",
    readById: "user/readById",
    update: "user/update",
    delete: "user/delete",
    readFileImport: "user/readFileImport",
    import: "user/import",
    changePhoto: "user/changePhoto",
  },
  messages: {
    getMessages: "message/getMessages",
    getMessagesUnreaded: "message/getMessagesUnreaded",
    getMessagesDetails: "message/getMessagesDetails",
    sendNotificationMessage: "message/sendNotificationMessage",
    addMessage: "message/addMessage",
    updateMessageRead: "message/updateMessageRead",
  },
  device: {
    readByToken: "device/readByToken",
    read: "device/read",
    create: "device/create",
  },
  welcomeLetter: {
    read: "welcomeLetter/read",
    update: "welcomeLetter/update",
  },
  appSettings: {
    read: "appSettings/read",
    update: "appSettings/update",
  },
  eventSettings: {
    read: "eventSettings/read",
    update: "eventSettings/update",
  },
  sponsor: {
    read: "sponsor/read",
    create: "sponsor/create",
    update: "sponsor/update",
    delete: "sponsor/delete",
  },
};

export default endpoints;
