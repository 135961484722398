import {
  Row,
  Col,
  Button,
  Spin,
  Divider,
  Pagination,
  Empty,
  Form,
  Input,
  Select,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints";
import Create from "../../components/Commission/Create";
import Update from "../../components/Commission/Update";
import Delete from "../../components/Commission/Delete";

import "./Commission.css";
import Search from "antd/es/input/Search";
import config from "../../utils/config";

function Commission() {
  const [commission, setCommission] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [commissionPosition, setCommissionPosition] = useState([]);
  const [commissionType, setCommissionType] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.commission.read)
      .then((response) => {
        let commissionDataServer = response.data[0];
        let commissionPositionDataServer = response.data[1];
        let commissionTypeDataServer = response.data[2];
        setCommission(commissionDataServer);
        setCommissionPosition(commissionPositionDataServer);
        setCommissionType(commissionTypeDataServer);

        let formFilter = form.getFieldsValue();
        if (formFilter.search || formFilter.room || formFilter.date) {
          handleChange(null, formFilter, commissionDataServer);
        } else {
          handlePrepareData(commissionDataServer);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(commissionDataServer) {
    setIsLoading(true);
    let commissionData = [];

    for (let i = 0; i < commissionDataServer.length; i++) {
      let arrayData = commissionDataServer[i];

      let aux = {
        key: i,
        id: arrayData.id,
        img: (
          <div
            className="img-preview"
            style={{
              backgroundImage: arrayData.img
                ? `url('${config.server_ip}media/${arrayData.img}')`
                : `url('${config.server_ip}media/User-default.svg')`,
            }}
          ></div>
        ),
        name: arrayData.name,
        id_commission_type: arrayData.id_commission_type,
        commission_type: arrayData.commission_type_name,
        id_commission_position: arrayData.id_commission_position,
        commission_position: arrayData.commission_position_name,
        work: arrayData.work,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined
              className="edit-action"
              onClick={() => handleOpenDrawerEditCommission(arrayData)}
            />
            <DeleteOutlined
              className="delete-action"
              onClick={() => handleOpenModalDeleteCommission(arrayData)}
            />
          </div>
        ),
      };

      commissionData.push(aux);
    }

    setTableData(commissionData);
    setIsLoading(false);
  }

  function handleChangePage(e, pageSize) {
    setIsLoading(true);
    setCurrentPage(e);
    let auxItemsPerPage = pageSize ? pageSize : itemsPerPage;
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(pageSize ? pageSize : auxItemsPerPage);
      setIsLoading(false);
    } else {
      let newMinValue = auxItemsPerPage * (e - 1);
      let newMaxValue = newMinValue + auxItemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
      setIsLoading(false);
    }
  }

  function handleOpenDrawerAddCommission() {
    setIsOpenAdd(true);
  }

  function handleCloseDrawerAddCommission() {
    setIsOpenAdd(false);
    handleGetData();
  }

  function handleOpenDrawerEditCommission(data) {
    setSelectedCommission(data);
    setIsOpenEdit(true);
  }

  function handleCloseDrawerEditCommission() {
    setSelectedCommission({});
    setIsOpenEdit(false);
    handleGetData();
  }

  function handleOpenModalDeleteCommission(data) {
    setSelectedCommission(data);
    setIsOpenDelete(true);
  }

  function handleCloseModalDeleteCommission() {
    setSelectedCommission({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(value, auxData) {
    return new Promise((resolve, reject) => {
      console.log(value);
      if (value) {
        const newData = auxData.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        resolve(newData);
      } else {
        resolve(auxData);
      }
    });
  }

  function handleFilterType(data, value) {
    return new Promise((resolve, reject) => {
      if (value) {
        const newData = data.filter(
          (item) => item.id_commission_type === value
        );
        resolve(newData);
      } else {
        resolve(data);
      }
    });
  }

  function handleChange(_, allFields, fullData) {
    let auxData = fullData ? fullData : commission;
    handleSearch(allFields.search, auxData).then((searchData) => {
      console.log(searchData);
      handleFilterType(searchData, allFields.id_commission_type).then(
        (typeData) => {
          handlePrepareData(typeData);
        }
      );
    });
  }

  return (
    <div className="dashboard_page">
      <Create
        open={isOpenAdd}
        close={handleCloseDrawerAddCommission}
        commissionType={commissionType}
        commissionPosition={commissionPosition}
      />
      <Update
        data={selectedCommission}
        open={isOpenEdit}
        close={handleCloseDrawerEditCommission}
        commissionType={commissionType}
        commissionPosition={commissionPosition}
      />
      <Delete
        data={selectedCommission}
        open={isOpenDelete}
        close={handleCloseModalDeleteCommission}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={4} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Comissão</h3>
        </Col>
        <Col span={20} className="d-flex jc-end ai-center">
          <Form form={form} layout="inline" onValuesChange={handleChange}>
            <Form.Item name="id_commission_type">
              <Select
                size="large"
                placeholder="Procurar..."
                allowClear
                options={commissionType.map((item, index) => ({
                  label: item.name,
                  value: item.id,
                }))}
                className="maxw-200 minw-200"
              />
            </Form.Item>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                size="large"
                placeholder="Procurar..."
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            size="large"
            onClick={handleOpenDrawerAddCommission}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row gutter={[24, 0]} className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={2}>
                          <div className="d-flex jc-center ai-center mr-20">
                            {item.img}
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Tipo de Comissão</span>
                            <span className="f-18">{item.commission_type}</span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Posição</span>
                            <span className="f-18">
                              {item.commission_position}
                            </span>
                          </div>
                        </Col>
                        <Col span={6} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={commission.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Commission;
