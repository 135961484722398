import {
  Row,
  Col,
  Button,
  Form,
  Divider,
  Pagination,
  Spin,
  Empty
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';

import config from "../../utils/config";
import Create from "../../components/OralCommunication/Create";
import Update from "../../components/OralCommunication/Update";
import Delete from "../../components/OralCommunication/Delete";

import Search from "antd/es/input/Search";
import endpoints from "../../utils/endpoints";

function ComunicacoesMiniOrais() {

  const [comunicacoesMiniOrais, setComunicacoesMiniOrais] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedComunicacoesMiniOrais, setSelectedComunicacoesMiniOrais] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();

  useEffect(() => {
    handleGetComunicacoesMiniOrais();
  }, []);

  function handleGetComunicacoesMiniOrais() {
    setIsLoading(true);
    axios.get(endpoints.communication.read).then((response) => {
      setComunicacoesMiniOrais(response.data);
      handlePrepareData(response.data);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let auxArray = [];
    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];
      let aux = {
        key: i,
        number: (i + 1),
        name: arrayData.name,
        link: <LinkOutlined className="link-action" onClick={() => window.open(arrayData.link)} />,
        actions: (
          <>
            <div className="actions-buttons-div">
              <EditOutlined className="edit-action" onClick={() => handleOpenModalEditComunicacoesMiniOrais(arrayData)} />
              <DeleteOutlined className="delete-action" onClick={() => handleOpenModalDeleteComunicacoesMiniOrais(arrayData)} />
            </div>
          </>)
      }

      auxArray.push(aux);
    }

    setTableData(auxArray)
    setIsLoading(false);
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0)
      setMaxValue(itemsPerPage)
    } else {
      let newMinValue = (itemsPerPage * (e - 1));
      let newMaxValue = newMinValue + itemsPerPage
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenDrawerAddComunicacoesMiniOrais() {
    setIsOpenAdd(true);
  }

  function handleCloseModalAddComunicacoesMiniOrais() {
    setIsOpenAdd(false);
    handleGetComunicacoesMiniOrais();
  }

  function handleOpenModalEditComunicacoesMiniOrais(data) {
    setSelectedComunicacoesMiniOrais(data);
    setIsOpenEdit(true);
  }

  function handleCloseModalEditComunicacoesMiniOrais(data) {
    setSelectedComunicacoesMiniOrais({});
    setIsOpenEdit(false);
    handleGetComunicacoesMiniOrais();
  }

  function handleOpenModalDeleteComunicacoesMiniOrais(data) {
    setSelectedComunicacoesMiniOrais(data);
    setIsOpenDelete(true);
  }

  function handleCloseModalDeleteComunicacoesMiniOrais(data) {
    setSelectedComunicacoesMiniOrais({});
    setIsOpenDelete(false);
    handleGetComunicacoesMiniOrais();
  }

  function handleSearch(e) {
    let value = e.target.value;
    setSearchText(value)
    const newData = comunicacoesMiniOrais.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    )

    handlePrepareData(newData)
  }

  return (
    <div className='dashboard_page'>
      <Create open={isOpenAdd} close={handleCloseModalAddComunicacoesMiniOrais} />
      <Update data={selectedComunicacoesMiniOrais} open={isOpenEdit} close={handleCloseModalEditComunicacoesMiniOrais} />
      <Delete data={selectedComunicacoesMiniOrais} open={isOpenDelete} close={handleCloseModalDeleteComunicacoesMiniOrais} />
      <Row className='title_row mb-20 dashboard_title_row'>
        <Col span={16} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Comunicacoes Mini-Orais</h3>
        </Col>
        <Col span={8} className="d-flex jc-end ai-center">
          <Search value={searchText} className="mr-20" size="large" placeholder="Procurar..." allowClear onChange={handleSearch} />
          <Button size="large" onClick={handleOpenDrawerAddComunicacoesMiniOrais}>Adicionar</Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ?
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={14}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={4} className="d-flex jc-center ai-center">
                          {item.type}
                        </Col>
                        <Col span={4} className="d-flex jc-center ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Link</span>
                            <span className="d-flex ai-center">{item.link}</span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    )
                  })
                  }
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={comunicacoesMiniOrais.length} />
                </div>
              </div>
              :
              <Empty description="This table is empty" />
            }
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default ComunicacoesMiniOrais;
