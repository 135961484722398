import {
  Row,
  Col,
  Radio,
  Divider,
  Button,
  Spin,
  notification,
  Tabs,
  Form,
  Upload,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';
import ReactQuill from "react-quill";

import config from "../../utils/config.js";

import "./WelcomeLetter.css";
import "react-quill/dist/quill.snow.css";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import endpoints from "../../utils/endpoints.js";

export default function WelcomeLetter() {

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileListSignatureOne, setFileListSignatureOne] = useState([]);
  const [fileListSignatureTwo, setFileListSignatureTwo] = useState([]);
  const [fileListSignatureThree, setFileListSignatureThree] = useState([]);
  const [filePreviewSignatureOne, setFilePreviewSignatureOne] = useState('');
  const [filePreviewSignatureTwo, setFilePreviewSignatureTwo] = useState('');
  const [filePreviewSignatureThree, setFilePreviewSignatureThree] = useState('');

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData()
  }, [])

  const uploadPropsSignatureOne = {
    name: 'file',
    maxCount: 1,
    onRemove: (file) => {
      const index = fileListSignatureOne.indexOf(file);
      const newFileList = fileListSignatureOne.slice();
      newFileList.splice(index, 1);
      setFileListSignatureOne(newFileList);
      setFilePreviewSignatureOne('');
    },
    beforeUpload: (file) => {
      handlePreviewSignatureOne(file);
      setFileListSignatureOne([file]);
      return false;
    },
    fileList: fileListSignatureOne,
    defaultFileList: []
  };

  const uploadPropsSignatureTwo = {
    name: 'file',
    maxCount: 1,
    onRemove: (file) => {
      const index = fileListSignatureTwo.indexOf(file);
      const newFileList = fileListSignatureTwo.slice();
      newFileList.splice(index, 1);
      setFileListSignatureTwo(newFileList);
      setFilePreviewSignatureTwo('');
    },
    beforeUpload: (file) => {
      handlePreviewSignatureTwo(file);
      setFileListSignatureTwo([file]);
      return false;
    },
    fileList: fileListSignatureTwo,
    defaultFileList: []
  };

  const uploadPropsSignatureThree = {
    name: 'file',
    maxCount: 1,
    onRemove: (file) => {
      const index = fileListSignatureThree.indexOf(file);
      const newFileList = fileListSignatureThree.slice();
      newFileList.splice(index, 1);
      setFileListSignatureThree(newFileList);
      setFilePreviewSignatureThree('');
    },
    beforeUpload: (file) => {
      handlePreviewSignatureThree(file);
      setFileListSignatureThree([file]);
      return false;
    },
    fileList: fileListSignatureThree,
    defaultFileList: []
  };

  function handleGetData() {
    axios.get(endpoints.welcomeLetter.read).then((res) => {
      let auxData = res.data[0];
      if (auxData.signature_one) {
        auxData.signature_one = [{ uid: 'not_new_1', name: auxData.signature_one }]
        setFileListSignatureOne(auxData.signature_one)
        setFilePreviewSignatureOne(auxData.signature_one[0].name)
      }

      if (auxData.signature_two) {
        auxData.signature_two = [{ uid: 'not_new_2', name: auxData.signature_two }]
        setFileListSignatureTwo(auxData.signature_two)
        setFilePreviewSignatureTwo(auxData.signature_two[0].name)
      }

      if (auxData.signature_three) {
        console.log(auxData.signature_three)
        auxData.signature_three = [{ uid: 'not_new_3', name: auxData.signature_three }]
        setFileListSignatureThree(auxData.signature_three)
        setFilePreviewSignatureThree(auxData.signature_three[0].name)
      }

      form.setFieldsValue(auxData);
    }).catch((err) => {
      console.log(err)
    })
  }

  function handleSubmit(values) {
    console.log(values);
    setIsLoading(true);
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileListSignatureOne.length > 0) {
      fileListSignatureOne.forEach((file) => {
        if (file.uid !== 'not_new_1' && file.uid !== 'not_new_2' && file.uid !== 'not_new_3') {
          formData.append('signature_one', file);
        } else {
          values.signature_one = file.name
        }
      });
    } else {
      values.signature_one = null
    }

    if (fileListSignatureTwo.length > 0) {
      fileListSignatureTwo.forEach((file) => {
        console.log(file);
        if (file.uid !== 'not_new_1' && file.uid !== 'not_new_2' && file.uid !== 'not_new_3') {
          formData.append('signature_two', file);
        } else {
          values.signature_two = file.name
        }
      });
    } else {
      values.signature_two = null
    }

    if (fileListSignatureThree.length > 0) {
      fileListSignatureThree.forEach((file) => {
        console.log(file);
        if (file.uid !== 'not_new_1' && file.uid !== 'not_new_2' && file.uid !== 'not_new_3') {
          formData.append('signature_three', file);
        } else {
          values.signature_three = file.name
        }
      });
    } else {
      values.signature_three = null
    }

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.welcomeLetter.update}`, {
      method: "POST",
      body: formData
    }).then((response) => {
      notification.success({
        message: 'Yeah!',
        description: 'Carta de boas-vindas foi editada com sucesso!'
      })
      setIsLoading(false);
      setIsButtonLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    })
  }

  async function handlePreviewSignatureOne(file) {
    let filePreview = await getBase64(file);
    setFilePreviewSignatureOne(filePreview)
  };

  async function handlePreviewSignatureTwo(file) {
    let filePreview = await getBase64(file);
    setFilePreviewSignatureTwo(filePreview)
  };

  async function handlePreviewSignatureThree(file) {
    let filePreview = await getBase64(file);
    setFilePreviewSignatureThree(filePreview)
  };


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div className='dashboard_page'>
      <Row className='title_row mb-20 dashboard_title_row'>
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Carta de boas-vindas</h3>
        </Col>
      </Row>
      <Row className='dashboard_content_row'>
        <Form
          form={form}
          id="add-moderator-form"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            hidden
            name="id"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Texto"
            name="text"
          >
            <ReactQuill
              className="welcome_letter"
            />
          </Form.Item>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                name="signature_one"
                label="Assinatura"
                valuePropName='fileListSignatureOne'
                getValueFromEvent={normFile}
              >
                <Dragger {...uploadPropsSignatureOne} className={`dragger ${filePreviewSignatureOne ? 'hasPreview' : ''}`}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <div
                    className={`preview_file ${filePreviewSignatureOne ? 'hasPreview' : ''}`}
                    style={{
                      backgroundImage: `url(${(filePreviewSignatureOne && filePreviewSignatureOne.includes('base64')) ?
                        filePreviewSignatureOne :
                        `${config.server_ip}media/${filePreviewSignatureOne}`})`
                    }}
                  >
                  </div>
                </Dragger>
              </Form.Item>
              <Form.Item
                label="Nome"
                name="signature_one_name"
              >
                <Input size="large" placeholder="Nome..." />
              </Form.Item>
              <Form.Item
                label="Posição"
                name="signature_one_position"
              >
                <Input size="large" placeholder="Posição..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="signature_two"
                label="Assinatura"
                valuePropName='fileListSignatureTwo'
                getValueFromEvent={normFile}
              >
                <Dragger {...uploadPropsSignatureTwo} className={`dragger ${filePreviewSignatureTwo ? 'hasPreview' : ''}`}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <div
                    className={`preview_file ${filePreviewSignatureTwo ? 'hasPreview' : ''}`}
                    style={{
                      backgroundImage: `url(${(filePreviewSignatureTwo && filePreviewSignatureTwo.includes('base64')) ?
                        filePreviewSignatureTwo :
                        `${config.server_ip}media/${filePreviewSignatureTwo}`})`
                    }}
                  >
                  </div>
                </Dragger>
              </Form.Item>
              <Form.Item
                label="Nome"
                name="signature_two_name"
              >
                <Input size="large" placeholder="Nome..." />
              </Form.Item>
              <Form.Item
                label="Posição"
                name="signature_two_position"
              >
                <Input size="large" placeholder="Posição..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="signature_three"
                label="Assinatura"
                valuePropName='fileListSignatureThree'
                getValueFromEvent={normFile}
              >
                <Dragger {...uploadPropsSignatureThree} className={`dragger ${filePreviewSignatureThree ? 'hasPreview' : ''}`}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <div
                    className={`preview_file ${filePreviewSignatureThree ? 'hasPreview' : ''}`}
                    style={{
                      backgroundImage: `url(${(filePreviewSignatureThree && filePreviewSignatureThree.includes('base64')) ?
                        filePreviewSignatureThree :
                        `${config.server_ip}media/${filePreviewSignatureThree}`})`
                    }}
                  >
                  </div>
                </Dragger>
              </Form.Item>
              <Form.Item
                label="Nome"
                name="signature_three_name"
              >
                <Input size="large" placeholder="Nome..." />
              </Form.Item>
              <Form.Item
                label="Posição"
                name="signature_three_position"
              >
                <Input size="large" placeholder="Posição..." />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button loading={isButtonLoading} size="large" type="primary" onClick={form.submit}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
}
