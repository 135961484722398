import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Input,
  Form,
  Avatar
} from 'antd';
import { EditOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from "axios";
import dayjs from "dayjs";

import Update from "../../components/User/Update";
import Create from "../../components/User/Create";
import Import from "../../components/User/Import";
import Delete from "../../components/User/Delete";
import endpoints from "../../utils/endpoints";
import DrawerExportTable from "../../components/Export/DrawerExportTable";

import './Users.css'
import config from "../../utils/config";

const Users = () => {

  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: '',
      dataIndex: 'img',
      width: 40,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Profissão',
      dataIndex: 'job_title',
    },
    {
      title: '',
      dataIndex: 'actions',
    },
  ];

  useEffect(() => {
    handleGetUsers();
  }, []);

  function handleGetUsers() {
    setIsLoading(true);
    axios.get(endpoints.user.read)
      .then(res => {
        setUsers(res.data);
        handlePrepareData(res.data);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error)
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        img: <Avatar src={`${config.server_ip}media/${data[i].img}`} />,
        name: data[i].name,
        email: data[i].email,
        job_title: data[i].job_title,
        actions:
          <div className="d-flex jc-end">
            <Button onClick={() => handleOpenUpdate(data[i])}><EditOutlined /></Button>
            {!data[i].is_admin &&
              <Button className="ml-10" onClick={() => handleOpenDelete(data[i])}><DeleteOutlined /></Button>
            }
          </div>,
        full_data: data[i]
      }

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetUsers();
  }

  function handleOpenUpdate(data) {
    setSelectedUser(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetUsers();
  }

  function handleOpenDelete(data) {
    setSelectedUser(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedUser({});
    setIsOpenDelete(false);
    handleGetUsers();
  }

  function handleOpenImport() {
    setIsOpenImport(true);
  }

  function handleCloseImport() {
    setIsOpenImport(false);
    handleGetUsers();
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = users.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData)
  }

  function handleOpenExport() {
    setIsOpenExportDrawer(true);
  }

  function handleCloseExport() {
    setIsOpenExportDrawer(false);
  }

  return (
    <div className='dashboard_page'>
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Import open={isOpenImport} close={handleCloseImport} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedUser} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedUser} />
        <DrawerExportTable open={isOpenExportDrawer} close={handleCloseExport} data={tableData} />
        <Col span={24}>

          <Row className='title_row mb-20 dashboard_title_row'>
            <Col span={6}>
              <h3 className="mt-0 mb-0">Users</h3>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <Form onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                  <Form.Item name="search" className="mb-0 mr-10" >
                    <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Search..." />
                  </Form.Item>
                </Form>
                <Button className="mr-10" size="large" onClick={handleGetUsers}><ReloadOutlined /></Button>
                <Button className="mr-10" size="large" onClick={handleOpenExport}>Export</Button>
                <Button className="mr-10" size="large" onClick={handleOpenImport}>Import</Button>
                <Button size="large" onClick={handleOpenCreate}>Create</Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={tableData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Users;