import {
  Row,
  Col,
  Button,
  Divider,
  Avatar,
  Tooltip,
  Pagination,
  Spin,
  Popover,
  Empty,
  DatePicker,
  Select,
  Input,
  Form,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  InboxOutlined,
  FilterOutlined,
  SearchOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { FiClock, FiCalendar, FiMapPin } from "react-icons/fi";

import config from "../../utils/config.js";
import Create from "../../components/Program/Create";
import Update from "../../components/Program/Update";
import Delete from "../../components/Program/Delete";

import "./Program.css";
import "react-quill/dist/quill.snow.css";
import Search from "antd/es/input/Search";
import endpoints from "../../utils/endpoints.js";
import { useForm } from "antd/es/form/Form.js";

function Program() {
  const [program, setProgram] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [eventSettings, setEventSettings] = useState([]);
  const [eventDays, setEventDays] = useState([]);
  const [eventRooms, setEventRooms] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  const [form] = useForm();

  useEffect(() => {
    handleGetProgram();
  }, []);

  function handleGetProgram() {
    setIsLoading(true);
    axios
      .get(endpoints.program.read)
      .then((response) => {
        console.log(response.data);
        setProgram(response.data.program);
        setFaculty(response.data.faculty);
        setEventSettings(response.data.event_settings);
        setEventDays(
          JSON.parse(
            response.data.event_settings.filter(
              (item) => item.name === "event_days"
            )[0].json
          ).event_days
        );
        setEventRooms(
          JSON.parse(
            response.data.event_settings.filter(
              (item) => item.name === "event_rooms"
            )[0].json
          ).event_rooms
        );

        let formFilter = form.getFieldsValue();
        if (formFilter.search || formFilter.room || formFilter.date) {
          handleChange(null, formFilter, response.data.program);
        } else {
          handlePrepareData(response.data.program, response.data.faculty);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data, facultyData) {
    console.log(data);
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < data.length; i++) {
      let mods = [];
      let speakers = [];
      let modsArray = JSON.parse(data[i].mods);
      let speakersArray = JSON.parse(data[i].speakers);

      if (modsArray !== null) {
        for (let z = 0; z < modsArray.length; z++) {
          let modOfProgram = facultyData.find((x) => x.id === modsArray[z]);
          mods.push(modOfProgram);
        }
      }

      if (speakersArray !== null) {
        for (let z = 0; z < speakersArray.length; z++) {
          let speakerOfProgram = facultyData.find(
            (x) => x.id === speakersArray[z]
          );
          speakers.push(speakerOfProgram);
        }
      }

      let arrayData = data[i];

      let aux = {
        key: i,
        title: (
          <span
            className="title-program"
            dangerouslySetInnerHTML={{ __html: arrayData.title }}
          ></span>
        ),
        local: arrayData.local,
        mods: mods,
        speakers: speakers,
        date: dayjs(arrayData.date).format("YYYY-MM-DD"),
        hourStart: `${arrayData.hour_start.split(":")[0]}:${
          arrayData.hour_start.split(":")[1]
        }`,
        hourEnd: `${arrayData.hour_end.split(":")[0]}:${
          arrayData.hour_end.split(":")[1]
        }`,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined
              className="edit-action"
              onClick={() => handleOpenDrawerEditProgram(data[i])}
            />
            <DeleteOutlined
              className="delete-action"
              onClick={() => handleOpenModalDeleteProgram(data[i])}
            />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenDrawerAddProgram() {
    setIsOpenAdd(true);
  }

  function handleCloseDrawerAddProgram() {
    setIsOpenAdd(false);
    handleGetProgram();
  }

  function handleChangePage(e, pageSize) {
    setIsLoading(true);
    setCurrentPage(e);
    let auxItemsPerPage = pageSize ? pageSize : itemsPerPage;
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(auxItemsPerPage);
      setIsLoading(false);
    } else {
      let newMinValue = auxItemsPerPage * (e - 1);
      let newMaxValue = newMinValue + auxItemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
      setIsLoading(false);
    }
  }

  function handleOpenDrawerEditProgram(data) {
    if (data) {
      let objAux = {
        id: data.id,
        title: data.title,
        title_color: data.title_color,
        has_description: data.has_description,
        description: data.description,
        local: data.local,
        mods: data.mods ? JSON.parse(data.mods) : null,
        speakers: data.speakers ? JSON.parse(data.speakers) : null,
        type: data.type,
        add_to_calendar: data.add_to_calendar,
        hourStart: data.hour_start,
        hourEnd: data.hour_end,
        date: dayjs(data.date),
        duration: [
          dayjs(data.hour_start, "HH:mm:ss"),
          dayjs(data.hour_end, "HH:mm:ss"),
        ],
        hide_local: data.hide_local,
        aha_slides: data.aha_slides,
        aha_slides_active: data.aha_slides_active,
        button_name: data.button_name,
        button_link: data.button_link,
      };

      setSelectedProgram(objAux);
      setIsOpenEdit(true);
    }
  }

  function handleCloseDrawerEditProgram() {
    setIsOpenEdit(false);
    handleGetProgram();
  }

  function handleOpenModalDeleteProgram(data) {
    setSelectedProgram(data);
    setIsOpenDelete(true);
  }

  function handleCloseModalDeleteProgram() {
    setSelectedProgram({});
    setIsOpenDelete(false);
    handleGetProgram();
  }

  function handleSearch(value, auxData) {
    return new Promise((resolve, reject) => {
      if (value) {
        const newData = auxData.filter((item) =>
          item.title.toLowerCase().includes(value.toLowerCase())
        );
        resolve(newData);
      } else {
        resolve(auxData);
      }
    });
  }

  function heandleFilterDate(data, value) {
    return new Promise((resolve, reject) => {
      if (value) {
        const newData = data.filter(
          (item) => dayjs(item.date).format("YYYY-MM-DD") === value
        );
        resolve(newData);
      } else {
        resolve(data);
      }
    });
  }

  function handleFilterRoom(data, value) {
    return new Promise((resolve, reject) => {
      if (value) {
        const newData = data.filter((item) => item.local === value);
        resolve(newData);
      } else {
        resolve(data);
      }
    });
  }

  function handleShowSizeChange(current, pageSize) {
    setItemsPerPage(pageSize);
    handleChangePage(current, pageSize);
  }

  function handleChange(_, allFields, fullData) {
    let auxData = fullData ? fullData : program;
    handleSearch(allFields.search, auxData).then((searchData) => {
      heandleFilterDate(searchData, allFields.date).then((dateData) => {
        handleFilterRoom(dateData, allFields.room).then((roomData) => {
          handlePrepareData(roomData, faculty);
        });
      });
    });
  }

  return (
    <div className="dashboard_page">
      <Create
        open={isOpenAdd}
        close={handleCloseDrawerAddProgram}
        faculty={faculty}
        eventSettings={eventSettings}
      />
      <Update
        data={selectedProgram}
        open={isOpenEdit}
        close={handleCloseDrawerEditProgram}
        faculty={faculty}
        eventSettings={eventSettings}
      />
      <Delete
        data={selectedProgram}
        open={isOpenDelete}
        close={handleCloseModalDeleteProgram}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={4} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Programa</h3>
        </Col>
        <Col span={20} className="d-flex jc-end ai-center">
          <Form layout="inline" form={form} onValuesChange={handleChange}>
            <Form.Item name="room">
              <Select
                size="large"
                options={eventRooms.map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                placeholder="Escolher sala..."
                allowClear
                className="maxw-200 minw-200"
              />
            </Form.Item>
            <Form.Item name="date">
              <Select
                size="large"
                options={eventDays.map((item) => ({
                  label: dayjs(item.day).format("YYYY-MM-DD"),
                  value: dayjs(item.day).format("YYYY-MM-DD"),
                }))}
                placeholder="Escolher dia..."
                allowClear
                className="maxw-200 minw-200"
              />
            </Form.Item>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                size="large"
                placeholder="Procurar..."
                allowClear
                className="maxw-200 minw-200"
              />
            </Form.Item>
          </Form>
          <Button size="large" className="mr-15" onClick={handleGetProgram}>
            <ReloadOutlined />
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={handleOpenDrawerAddProgram}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="program_item">
                        <Col span={24}>
                          <Row gutter={[24]}>
                            <Col
                              span={10}
                              className="d-flex jc-start ai-center"
                            >
                              <div className="d-flex flex-column">
                                <span className="f-12">Título</span>
                                <span className="f-18">{item.title}</span>
                              </div>
                            </Col>
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Moderadores</span>
                                <span className="mt-10">
                                  {item.mods.length > 0 ? (
                                    <Avatar.Group>
                                      {item.mods.map((itemMod) => (
                                        <Tooltip
                                          title={itemMod.name}
                                          placement="top"
                                        >
                                          {itemMod.img ? (
                                            <Avatar
                                              src={`${config.server_ip}media/${itemMod.img}`}
                                            />
                                          ) : (
                                            <Avatar
                                              className="empty"
                                              icon={<UserOutlined />}
                                            />
                                          )}
                                        </Tooltip>
                                      ))}
                                    </Avatar.Group>
                                  ) : (
                                    <Avatar.Group>
                                      <Avatar>TBA</Avatar>
                                    </Avatar.Group>
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Palestrantes</span>
                                <span className="mt-10">
                                  {item.speakers.length > 0 ? (
                                    <Avatar.Group>
                                      {item.speakers.map((itemSpeaker) => (
                                        <Tooltip
                                          title={itemSpeaker?.name}
                                          placement="top"
                                        >
                                          {itemSpeaker?.img ? (
                                            <Avatar
                                              src={`${config.server_ip}media/${itemSpeaker.img}`}
                                            />
                                          ) : (
                                            <Avatar
                                              className="empty"
                                              icon={<UserOutlined />}
                                            />
                                          )}
                                        </Tooltip>
                                      ))}
                                    </Avatar.Group>
                                  ) : (
                                    <Avatar.Group>
                                      <Avatar>TBA</Avatar>
                                    </Avatar.Group>
                                  )}
                                </span>
                              </div>
                            </Col>
                            <Col span={2} className="d-flex jc-end ai-center">
                              {item.actions}
                            </Col>
                          </Row>
                          <Divider />
                          <Row className="info">
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiCalendar className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Data</span>
                                  <span className="f-16 semi-bold">
                                    {item.date}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={6} className="d-flex jc-start ai-center">
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiClock className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Hora</span>
                                  <span className="f-16 semi-bold">
                                    {item.hourStart} - {item.hourEnd}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={6}>{item.type}</Col>
                            <Col span={6}>
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiMapPin className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Local</span>
                                  <span className="f-16 semi-bold">
                                    {item.local ? item.local : "TBA"}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    onShowSizeChange={handleShowSizeChange}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={tableData.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Program;
