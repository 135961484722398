import {
  Row,
  Col,
  Modal,
  Button,
  Input,
  Form,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import axios from 'axios';
import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

export default function Update({ data, open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios.post(endpoints.commissionPosition.update, {
      data: values
    }).then((resData) => {
      close();
      setIsButtonLoading(false);
      form.resetFields();
      notification.success({
        message: 'Yeah!',
        description: 'Posição de comissão foi editado com sucesso!'
      })
    }).catch((error) => {
      console.log(error);
      setIsButtonLoading(false);
      notification.error({
        message: 'Oops!',
        description: 'Algo de errado aconteceu! Tente novamente mais tarde.'
      })
    });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: 'Faltam alguns campos para preencher!'
    })
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={`Editar posição comissão`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button className="mr-20" size="large" type="primary" onClick={form.submit} loading={isButtonLoading}>
          Guardar
        </Button>,
        <Button disabled={isButtonLoading} size="large" onClick={handleCloseModal}>
          Cancelar
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              hidden
              name="id"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Nome"
              rules={[{ required: true, message: 'Este é um campo obrigatório' }]}
            >
              <Input size="large" placeholder="Nome..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
