import {
  Row,
  Col,
  Button,
  Form,
  Divider,
  Pagination,
  Spin,
  Empty,
  Input,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InboxOutlined,
  LinkOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import Search from "antd/es/input/Search";

import Create from "../../components/SponsorBanners/Create";
import Update from "../../components/SponsorBanners/Update";
import Delete from "../../components/SponsorBanners/Delete";
import config from "../../utils/config";

import "./SponsorBanners.css";
import endpoints from "../../utils/endpoints";

function SponsorBanners() {
  const [sponsors, setSponsors] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedSponsor, setSelectedSponsor] = useState({});
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetSponsors();
  }, []);

  function handleGetSponsors() {
    setIsLoading(true);
    axios
      .get(endpoints.sponsorBanners.read)
      .then((response) => {
        let sponsorsDataServer = response.data;
        setSponsors(sponsorsDataServer);
        handlePrepareData(sponsorsDataServer);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let auxArray = [];
    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];
      let aux = {
        key: i,
        img: (
          <img
            className="img_sponsor_table"
            src={`${config.server_ip}media/${arrayData.img}`}
          />
        ),
        name: arrayData.name,
        pos: arrayData.pos,
        link: (
          <LinkOutlined
            className="link-action"
            onClick={() => window.open(arrayData.link)}
          />
        ),
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined
              className="edit-action"
              onClick={() => handleOpenDrawerEditScientificSponsor(arrayData)}
            />
            <DeleteOutlined
              className="delete-action"
              onClick={() => handleOpenModalDeleteScientificSponsor(arrayData)}
            />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenDrawerAddScientificSponsor() {
    setIsOpenAdd(true);
  }

  function handleCloseDrawerAddScientificSponsor() {
    setIsOpenAdd(false);
    handleGetSponsors();
  }

  function handleOpenDrawerEditScientificSponsor(data) {
    setSelectedSponsor(data);
    setIsOpenEdit(true);
  }

  function handleCloseDrawerEditScientificSponsor() {
    setIsOpenEdit(false);
    setSelectedSponsor({});
    handleGetSponsors();
  }

  function handleOpenModalDeleteScientificSponsor(data) {
    setSelectedSponsor(data);
    setIsOpenDelete(true);
  }

  function handleCloseModalDeleteScientificSponsor() {
    setIsOpenDelete(false);
    setSelectedSponsor({});
    handleGetSponsors();
  }

  function handleSearch(e) {
    let value = e.target.value;
    setSearchText(value);
    const newData = sponsors.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    handlePrepareData(newData);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenAdd} close={handleCloseDrawerAddScientificSponsor} />
      <Update
        data={selectedSponsor}
        open={isOpenEdit}
        close={handleCloseDrawerEditScientificSponsor}
      />
      <Delete
        data={selectedSponsor}
        open={isOpenDelete}
        close={handleCloseModalDeleteScientificSponsor}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={8} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">MERCK</h3>
        </Col>
        <Col span={16} className="d-flex jc-end ai-center">
          <Form form={form} layout="inline" onValuesChange={handleSearch}>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                className="mr-20"
                size="large"
                placeholder="Procurar..."
                allowClear
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            size="large"
            onClick={handleOpenDrawerAddScientificSponsor}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={2}>
                          <div className="d-flex jc-center ai-center mr-20">
                            {item.img}
                          </div>
                        </Col>
                        <Col span={9}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={2}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Posição</span>
                            <span className="f-18 semi-bold">{item.pos}</span>
                          </div>
                        </Col>
                        <Col span={9} className="d-flex jc-center ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Link</span>
                            <span className="d-flex ai-center">
                              {item.link}
                            </span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={sponsors.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default SponsorBanners;
